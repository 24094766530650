
import { Component, Vue, Watch } from "vue-property-decorator";
import { apiShopDetail, apiShopChangeSuperAdmin } from "@/api/shop";
@Component
export default class UpdateAccount extends Vue {
    /** S Data **/

    identity: number | null = null; // 当前编辑用户的身份ID

    // 添加商城表单数据
    form: any = {
        super_admin: "", // 账号
        super_password: "", // 密码
        super_password_confirm: "", // 确认密码
    };

    // 表单校验
    rules = {
        super_admin: [
            { required: true, message: "请输入商城账号", trigger: "blur" },
        ],
        super_password: [
            { required: true, message: "请输入密码", trigger: "blur" },
            { min: 6, max: 20, message: '密码最小输入长度为6-20', trigger: 'blur' }
        ],
        super_password_confirm: [
            { required: true, message: "请再次输入密码", trigger: "blur" },
            { min: 6, max: 20, message: '密码最小输入长度为6-20', trigger: 'blur' },
            {
                validator: (rule: object, value: string, callback: any) => {
                    if (this.form.password) {
                        if (!value) callback(new Error("请再次输入密码"));
                        if (value !== this.form.password)
                            callback(new Error("两次输入密码不一致!"));
                    }
                    callback();
                },
                trigger: "blur",
            },
        ],
    };

    /** E Data **/

    /** S Methods **/
    // 点击表单提交
    onSubmit(formName: string) {
        // 验证表单格式是否正确
        const refs = this.$refs[formName] as HTMLFormElement;
        refs.validate((valid: boolean): any => {
            if (!valid) return;
            this.handleShopEdit();
        });
    }

    // 编辑超级管理员账号
    async handleShopEdit(): Promise<void> {
        await apiShopChangeSuperAdmin({ ...this.form, id: this.identity });
        setTimeout(() => this.$router.go(-1), 500);
    }

    // 获取详情
    async getShopDetailFunc(): Promise<void> {
        const res: any = await apiShopDetail({
            id: this.identity as number
        })
        this.form.super_admin = res.account // 账号
    }

    /** E Methods **/

    /** S Life Cycle **/
    created() {
        const query: any = this.$route.query;

        // 编辑模式：初始化数据
        this.identity = +query.id;
        if (+query.id) {
            this.getShopDetailFunc();
        }
    }
    /** E Life Cycle **/
}
